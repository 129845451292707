import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import lighten from 'polished/lib/color/lighten';

const Shimmer = styled.div<{
  color?: string;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
}>(
  ({ color, height, theme: { colours }, width }) => css`
    position: relative;
    overflow: hidden;
    width: ${width}px;
    height: ${height ? `${height}px` : '100%'};
    background-image: linear-gradient(
      to right,
      ${color || colours.surface.disabled} calc(50% - 100px),
      ${lighten(0.05, color || colours.surface.disabled)} 50%,
      ${color || colours.surface.disabled} calc(50% + 100px)
    );
    background-size: 0;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: calc(200% + 200px);
      animation: shimmer 2s linear infinite;
      background-image: inherit;
      content: '';
    }

    @keyframes shimmer {
      to {
        transform: translateX(calc(50% + 100px));
      }
    }
  `,
);

const LoadingShimmer = ({
  className,
  ...props
}: {
  className?: string;
  color?: string;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
}) => <Shimmer {...props} className={className} data-cy="loading-shimmer" />;

export const LoadingShimmerWithConfig = styled(LoadingShimmer)<{
  height?: number;
  width: number;
}>(({ height, width }) => [
  css`
    width: ${width}px;
    height: ${height ? height : 24}px;
  `,
]);

export default LoadingShimmer;
