import { ThemeZIndex } from '@ui-v2/types/layout';

export const legacyZIndices = {
  z1: 100,
  z2: 200,
  z3: 300,
  z4: 400,
  z5: 500,
  z6: 600,
  z7: 700,
  z8: 800,
  z9: 900,
  z10: 1000,
  max: 999999,
};

export const zIndices: ThemeZIndex = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  header: 10,
  modal: 102,
  // etc
};
